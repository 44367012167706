#debito * ,
#debito *:before,
#debito *:after {
    box-sizing: border-box;
}

#debito .container { width: 700px; margin: auto;}

#debito .header {
    width: 100%;
    float: left;
    background: #000;
    margin: 0 0 60px 0;
    display: block;
}

#debito .header img {
    position: relative;
    left: 50%;
    max-width: 100%;
    max-height: 578px;
    display: block;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}

#debito p {
    position: relative;
    float: left;
    width: 100%;
    font-size: 16px;
    color: #000;
    margin: 5px 0 20px;
    line-height: 22px;
}

#debito small {
    font-size: 13px;
    display: block;
}

#debito p strong { font-family: 'dinbold'; }

#debito a {
    color: #3399cc;
    text-decoration: underline;
    display: block;
}

#debito a.btn {
    float: left;
    background: #3399cc;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 10px 27px 8px;
    margin-bottom: 20px;
    margin-top: 10px;
    transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
}

#debito a.btn:last-of-type { margin-bottom: 0; }

#debito a.btn span {
    transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    float: left;
}

#debito a:hover { color: #2a607b; }

#debito .aviso {
    width: 100%;
    float: left;
    border: 3px solid  #39c;
    border-radius: 20px;
    margin: 45px 0;
    padding: 15px 25px 8px;
}

#debito .aviso .erro {
    float: left;
    width: 42px;
    height: 37px;
    background-image: url(http://yamaha-motor.com.br/consorcio/images/debito/aviso.png);
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-top: 8px;
}

#debito .aviso p {
    width: calc(100% - 55px);
    float: right;
    height: 37px;
}

#debito .links {
    width: 100%;
    float: left;
    border-top: 10px solid  #e5e5e5;
    margin-top: 60px;
    padding-top: 7px;
    margin-bottom: 60px;
}

#debito .links li {
    width: 220px;
    float: left;
    margin-right: 20px;
    padding-left: 80px;
    position: relative;
    text-transform: uppercase;
}

#debito .links li:last-of-type { margin-right: 0; }

#debito .links li:before {
    content: "";
    background-image: url(http://yamaha-motor.com.br/consorcio/images/debito/icons.png);
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
}

#debito .links li.icon-concessionarias:before { background-position: 0 0; }
#debito .links li.icon-representantes:before { background-position: -77px 0; }
#debito .links li.icon-canais:before { background-position: -154px 0; }

#debito .links li p,
#debito .links li a {
    margin: 0;
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 17px;
}

#debito .links li a {
    margin-top: 4px;
    display: block;
    float: left;
}