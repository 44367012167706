.MuiTableRow-root .MuiTableCell-root {
    padding: 8px 16px 8px 16px;
}

.MuiTableRow-root .MuiTableCell-root .MuiButtonBase-root {
    margin: 0;
}

.MuiList-root .MuiListItem-button {
    transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.MuiList-root .MuiListItem-button:hover {
    background-color: #33333311;
}

.MuiList-root .MuiListItem-button:active {
    /* background-color: #f00; */
    transform: scale(0.95);
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: lightgray;
  }
  ::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: gray;}
  
